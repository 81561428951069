
<script setup>
    import { ref, computed, defineProps, watchEffect } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts';
    import { cdnBaseUrl } from 'o365.modules.helpers.js';

    const DEFAULT_PICTURE = cdnBaseUrl+"/fontawesome/v.6.1.1/svgs/solid/user.svg";

    const defaultView = "sviw_System_PersonsLookup";

    const props = defineProps({
        viewName: {
            type: String,
            required: false,
            default: defaultView,
        },
        whereClause: {
            type: String,
            required: false,
            default: "",
        },
        dataObject: {
            type: Object,
            required: false,
        },
        noRecent: {
            type: Boolean,
            default: false
        }
    });

    const viewName = computed(() => props.viewName);
    const whereClause = computed(() => props.whereClause);
    const dataObject = computed(() => props.dataObject);
    const noRecent = computed(() => props.noRecent);

    const dsPersonsLkp = dataObject.value ? dataObject.value : (getOrCreateDataObject({
        id: 'o_dsPersonsLkp',
        viewName: viewName.value, 
        maxRecords: 100,
        loadRecents: !noRecent.value,
        fields: [
            { name: "ID", type: "number" },
            { name: "FirstName", type: "string" },
            { name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "Name", type: "string" },
            { name: "MobileNo", type: "string" },
            { name: "Represents", type: "string" },
            { name: "Email", type: "string" },
        ]
    }));

    if(props.whereClause){
        dsPersonsLkp.recordSource.whereClause = whereClause.value;
    }

    // Re-set data object options when relevant props changes, but only if we do not have a data object set directly.
    watchEffect(() => {
        if (!dataObject.value) {
            dsPersonsLkp.whereClause = whereClause.value || "";
            dsPersonsLkp.loadRecents = noRecent.value;
            dsPersonsLkp.viewName = viewName.value || defaultView;
        }    
    });

    function formatMobileNo(no) {
        if (!no) {
            return;
        }

        let match;

        // norwegian
        match = no.match(/^\+(\d{2})(\d{3})(\d{2})(\d{3})$/);
        if (match) {
            return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
        }

        return no;
    }
</script>

<template>
    <o-data-lookup :data-object="dsPersonsLkp">
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.person" :ref="scope.target" style="cursor: pointer;">
                <slot name="person"></slot>
            </span>
        </template>
        <template #mobileItem="{ item }">
            <div class="d-flex" style="background-color: white; border-bottom: 1px solid rgb(90%, 90%, 90%);">
                <img
                    class="flex-shrink-0 m-2"
                    :class="{ 'user-icon': !item.PictureFileRef }"
                    style="width: 4rem; height: 4rem; border-radius: 999px; object-fit: cover;"
                    :src="item.PictureFileRef ? `/api/file/view/sviw_System_PersonsImages/${item.PictureFileRef}` : DEFAULT_PICTURE"
                />
                <div class="flex-grow-1 pe-2">
                    <div class="mt-1" style="color: rgb(0%, 0%, 0%); font-weight: 500;">
                        {{ item.Name }}
                    </div>
                    <div style="color: rgb(40%, 40%, 40%); font-size: 0.9em;">
                        {{ item.Email }}
                    </div>
                    <div style="color: rgb(40%, 40%, 40%); font-size: 0.9em;">
                        <span>{{ formatMobileNo(item.MobileNo) }}</span>
                    </div>
                </div>
            </div>
        </template>
        <o-column field="Name" width="200"></o-column>
        <o-column field="MobileNo" width="150"></o-column>
        <o-column field="Email" width="200"></o-column>
        <o-column field="Represents" width="150"></o-column>
    </o-data-lookup>
</template>

<style scoped>
    .user-icon {
        filter: invert(93%) sepia(6%) saturate(618%) hue-rotate(178deg) brightness(98%) contrast(93%);
    }
</style>
